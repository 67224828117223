import "./reachUsComponent.scss";



const ReachUsComponent = () => {
  return (
    <>
    <div className="contact-form">
              <h2>Reach Us Anytime</h2>
              <form action="/submit_form" method="post">
                <label htmlFor="name">Name*</label><br />
                <input type="text" id="name" name="name" placeholder="Enter your name" required /><br />
                
                <label htmlFor="phone">Phone*</label><br />
                <input type="text" id="phone" name="phone" placeholder="Enter phone no." required /><br />
                
                <label htmlFor="email">Email*</label><br />
                <input type="email" id="email" name="email" placeholder="Enter email id" required /><br />
                
                <label htmlFor="message">Write Your Message*</label><br />
                <textarea id="message" name="message" placeholder="Write message" rows="4" required></textarea><br />
                
                <button type="submit">Submit Now</button>
              </form>
            </div>
    </>
  );
};

export default ReachUsComponent;

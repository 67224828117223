import React, { useState } from "react";
import './pkgFilterSearchComponent.scss';

const citiesData = [
  { name: 'Mumbai', count: 39 },
  { name: 'Jaisalmer', count: 24 },
  { name: 'Manali', count: 55 },
  { name: 'Udaipur', count: 30 },
  { name: 'Delhi', count: 24 },
  { name: 'Jaipur', count: 20 },
  { name: 'Goa', count: 15 },
  { name: 'Shimla', count: 12 },
  { name: 'Chandigarh', count: 18 },
  { name: 'Agra', count: 16 }
];

const PkgFilterSearchComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [checkedCities, setCheckedCities] = useState([]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleCheckboxChange = (cityName) => {
    setCheckedCities((prevCheckedCities) =>
      prevCheckedCities.includes(cityName)
        ? prevCheckedCities.filter((name) => name !== cityName)
        : [...prevCheckedCities, cityName]
    );
  };

  const filteredCities = citiesData.filter((city) =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedCities = showAll ? filteredCities : filteredCities.slice(0, 6);

  return (
    <div className="cities-filter">
      <div className="filter-heading" onClick={toggleDropdown}>
        <h2>Cities</h2>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>

      {isOpen && (
        <div className="filter-dropdown">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-box"
          />

          <div className="cities-list">
            {displayedCities.map((city, index) => (
              <label key={index}>
                <input
                  type="checkbox"
                  checked={checkedCities.includes(city.name)}
                  onChange={() => handleCheckboxChange(city.name)}
                />
                <span className="amount">
                <span>{city.name}</span>
                <span className="count">({city.count})</span>
                </span>
              </label>
            ))}

            {filteredCities.length > 6 && (
              <div className="show-more" onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Show Less' : 'Show More'}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PkgFilterSearchComponent;

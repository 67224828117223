import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import PkgBannerComponent from "../Components/PkgBannerComponent/PkgBannerComponent";
import  PackageDataComponent  from '../Components/PackageDataComponent/PackageDataComponent';
import  PackageDetailsComponent  from '../Components/PackageDetailsComponent/PackageDetailsComponent';
// import './innerPackageComponent.scss';

const PackageInner = () => {
  return (
    <>
      <HeaderComponent />
    <PkgBannerComponent />
    
    <div class='tour-package-inner-container alignment'>
            <div className='highlights'>
                <div className='high-root'>
                    <div className='left-section'>
                        <h2>Jaipur, Jodhpur, Udaipur Tour 5 Nights 6 Days</h2>
                        <div className='opt-btns'>
                            {['Standard', 'Deluxe', 'Super Deluxe', 'Premium'].map((item, index) => {
                                return <button className={item == 'Deluxe' ? 'active' : ''}>{item}</button>;
                            })}
                        </div>
                        <div className='opt-txt'>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown.
                                printer took a galley of type and scrambled it to make a type
                            </p>
                        </div>

                        <div>
                            {[
                                { icon: 'fas fa-phone', title: 'Duration', desc: '5 Nights 6 Days' },
                                { icon: 'fas fa-clock', title: 'Tour Type', desc: 'Rajasthan' },
                                { icon: 'fas fa-coffee', title: 'Language', desc: 'English' },
                            ].map((item, index) => {
                                return (
                                    <button className='icon-title-btn'>
                                        {' '}
                                        <div className="criterias"
                                        >
                                            <span>
                                                {' '}
                                                <i className={item.icon}></i>{' '}
                                            </span>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    textAlign: 'start',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        lineHeight: '0',
                                                        fontSize: '1.1rem',
                                                        fontWeight: 600,
                                                        margin: '0.5rem 0',
                                                    }}
                                                >
                                                    {' '}
                                                    {item.title}
                                                </p>
                                                <p style={{ lineHeight: '0', opacity: '0.7',}}> {item.desc}</p>
                                            </span>
                                        </div>
                                    </button>
                                );
                            })}
                        </div>
                        <div className='origin-high'>
                            <h3>Highlights of the Tour</h3>
                            <div className="highlight-section">
                                {[
                                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
                                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
                                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
                                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
                                ].map((item) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginTop: '1rem',
                                            }}
                                        >
                                            <div style={{ color: 'green', marginRight: '0.5rem', fontSize: '1.2rem' }}>
                                                ✔
                                            </div>
                                            <div style={{ opacity: '0.8', fontSize: '1.2rem' }}>{item}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <PackageDataComponent />
                    </div>
                    <div className='right-section'>
                        <>
                            <PackageDetailsComponent />
                        </>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <h2>End of Trip</h2>
            </div>
            <div className='do-dont'></div>
        </div>
    
    </>
  );
};

export default PackageInner;



import React from "react";
import "./sellingPackageComponent.scss";
import CardComponent from "../CardComponent/CardComponent";
import rajasthanImg from "../../assets/img/card-rajasthan-img.jpg"
import goaImg from "../../assets/img/card-goa-img.jpg"
import northEasImg from "../../assets/img/card-northeast-img.jpg"
import keralaImg from "../../assets/img/card-kerala-img.jpg"
import southIndiaImg from "../../assets/img/card-southindia-img.jpg"
import SingaporeImg from "../../assets/img/card-singapore-img.jpg"
import KashmirImg from "../../assets/img/card-kasmir-img.jpg"
import thailandImg from "../../assets/img/card-singapore-img.jpg"
import AndamanImg from "../../assets/img/card-andaman-img.jpg"
import sikkimImg from "../../assets/img/card-sikkim-img.jpg"


const SellingPackageComponent = ({
  title = "Most Popular Packages",
  exploreUnexpectedData = [],
}) => {
  const states = [
    {
      id: 1,
      name: "Rajasthan",
      img: rajasthanImg,
    },
    {
      id: 2,
      name: "Goa",
      img: goaImg,
    },
    {
      id: 3,
      name: "South india",
      img: northEasImg,
    },
    {
      id: 4,
      name: "Thailand",
      img: keralaImg,
    },
    {
      id: 5,
      name: "Kerala",
      img: AndamanImg,
    },
    {
      id: 6,
      name: "Andaman",
      img: sikkimImg,
    },
    {
      id: 7,
      name: "Kashmir",
      img: southIndiaImg,
    },
    {
      id: 8,
      name: "North-East",
      img:SingaporeImg,
    },
    {
      id: 9,
      name: "Sikkim",
      img:thailandImg,
    },
    {
      id: 10,
      name: "Singapore",
      img: KashmirImg,
    },
  ];
  return (
    <div className="explore-cont">
      <h1>{title}</h1>
      <div className="top-selling">
        {!exploreUnexpectedData.length ? (
          <>
            {" "}
            <h2>Jackpot Deals on Top Selling Packages</h2>
            <p className="p-tag">Save extra with our exclusive deals!</p>
          </>
        ) : (
          ""
        )}
        <div className="carousel-parent">
          <CardComponent
            allStates={
              exploreUnexpectedData.length ? exploreUnexpectedData : states
            }
            isDescNeeded={ exploreUnexpectedData.length ?true:false}
            isExploreUnexpected={exploreUnexpectedData.length? true: false}
          />
        </div>
      </div>
    </div>
  );
};

export default SellingPackageComponent;

import React from "react";
import "./footerComponent.scss";
import { CiMail } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="footer-first-row">
        <div>
          <h4>Join Our Mailing List</h4>
          <p>
            Get regular updates about upcoming events, trip planning advice and
            compelling stories.
          </p>
          <button>
            <CiMail size={28}/> Sign up for our E-Newsletter
          </button>
          <div className="footer-social">
            <FaFacebookF style={{ background: "#4A6EA9" }} size={23} />
            <FaInstagram size={23} />
          </div>
        </div>
        <div>
          <h4>Quick Links</h4>
          <p>Home</p>
          <p>About Us</p>
          <p>Contact Us</p>
        </div>
        <div>
          <h4>Other Popular Tours</h4>
          <p>North Bengal Offbeat</p>
          <p>Himachal</p>
          <p>Kashmir</p>
          <p>Leh Ladakh</p>
          <p>Sikkim</p>
          <p>Weekend Gateway</p>
        </div>
      </div>
      <div className="footer-second-row">
        <p>Privacy Policy</p>

        <p>Copyright © 2024 Ecstatic Explorers - All Rights Reserved</p>

        <span >
          <FaWhatsapp />
          <IoIosCall className="call-icon" />
        </span>
      </div>
    </div>
  );
};

export default FooterComponent;

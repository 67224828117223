import React from "react";

const AboutUs = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>progress</p>
    </div>
  );
};

export default AboutUs;
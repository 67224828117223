import React from "react";
import "./exploreByStatesComponent.scss";
import CardComponent from "../CardComponent/CardComponent";
import rajasthanImg from "../../assets/img/card-rajasthan-img.jpg"
import goaImg from "../../assets/img/card-goa-img.jpg"
import northEasImg from "../../assets/img/card-northeast-img.jpg"
import keralaImg from "../../assets/img/card-kerala-img.jpg"
import southIndiaImg from "../../assets/img/card-southindia-img.jpg"
import SingaporeImg from "../../assets/img/card-singapore-img.jpg"
import KashmirImg from "../../assets/img/card-kasmir-img.jpg"
import thailandImg from "../../assets/img/card-singapore-img.jpg"
import AndamanImg from "../../assets/img/card-andaman-img.jpg"
import sikkimImg from "../../assets/img/card-sikkim-img.jpg"




const ExploreByStatesComponent = () => {
  const states = [
    {
      id: 1,
      name: "Rajasthan",
      desc: "Starting at $400 Per Person",
      img: rajasthanImg
    },
    {
      id: 2,
      name: "Goa",
      desc: "Starting at $500 Per Person ",
      img: goaImg
    },
    {
      id: 3,
      name: "South india",
      desc: "Starting at $300 Per Person ",
      img: southIndiaImg
    },
    {
      id: 4,
      name: "Thailand",
      desc: "Starting at $600 Per Person ",
      img: thailandImg
    },
    {
      id: 5,
      name: "Kerala",
      desc: "Starting at $700 Per Person ",
      img: keralaImg
    },
    {
      id: 6,
      name: "Andaman",
      desc: "Starting at $100 Per Person ",
      img: AndamanImg
    },
    {
      id: 7,
      name: "Kashmir",
      desc: "Starting at $400 Per Person ",
      img: KashmirImg
    },
    {
      id: 8,
      name: "North-East",
      desc: "Starting at $450 Per Person ",
      img: northEasImg
    },
    {
      id: 9,
      name: "Sikkim",
      desc: "Starting at $899 Per Person ",
      img: sikkimImg
    },
    {
      id: 10,
      name: "Singapore",
      desc: "Starting at $499 Per Person ",
      img: SingaporeImg
    },

  ];
  return (

    <div className="explore-cont">
      <h1>Explore By States</h1>
      <div className="explore-by-states">
        <CardComponent allStates={states} isDescNeeded={true} isExploreUnexpected={false} />
      </div>
    </div>

  );
};

export default ExploreByStatesComponent;

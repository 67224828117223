import React from 'react'
import './exploreUnexpectedComponent.scss'
import SellingPackageComponent from '../SellingPackageComponent/SellingPackageComponent'
import rajasthanImg from "../../assets/img/card-rajasthan-img.jpg"
import { Link } from "react-router-dom";
import northEasImg from "../../assets/img/card-northeast-img.jpg"
import keralaImg from "../../assets/img/card-kerala-img.jpg"
import southIndiaImg from "../../assets/img/card-southindia-img.jpg"
import SingaporeImg from "../../assets/img/card-singapore-img.jpg"
import KashmirImg from "../../assets/img/card-kasmir-img.jpg"
import thailandImg from "../../assets/img/card-singapore-img.jpg"
import AndamanImg from "../../assets/img/card-andaman-img.jpg"
import sikkimImg from "../../assets/img/card-sikkim-img.jpg"





const ExploreUnexpectedComponent = () => {
  const states = [
    {
      id: 1,
      name: "Vietnam",
      desc: "Starting at $400 Per Person",
      img: AndamanImg
    },   
     {
      id: 2,
      name: "Mauritius",
      desc: "Starting at $500 Per Person ",
      img: KashmirImg
    },
    {
      id: 3,
      name: "Gujarat",
      desc: "Starting at $300 Per Person ",
      img: southIndiaImg
    },
    {
      id: 4,
      name: "Maharashtra",
      desc: "Starting at $600 Per Person ",
      img:SingaporeImg
    },
    {
      id: 5,
      name: "Uttarakhand",
      desc: "Starting at $700 Per Person ",
      img: keralaImg
    },
    {
      id: 6,
      name: "South Africa",
      desc: "Starting at $100 Per Person ",
      img: northEasImg
    },
    {
      id: 7,
      name: "Egypt",
      desc: "Starting at $400 Per Person ",
      img: rajasthanImg
    },
    {
      id: 8,
      name: "North-East",
      desc: "Starting at $450 Per Person ",
      img: AndamanImg
    },
    {
      id: 9,
      name: "Sikkim",
      desc: "Starting at $899 Per Person ",
      img: sikkimImg
    },
    {
      id: 10,
      name: "Singapore",
      desc: "Starting at $499 Per Person ",
      img:northEasImg
    },
     
  ];
  return (
    <div className='unexpected'>
        <Link to="/package" style={{ textDecoration: 'none' }}>
          <SellingPackageComponent title="Explore the unexplored"  exploreUnexpectedData={states} />
        </Link>
    </div>
  )
}

export default ExploreUnexpectedComponent
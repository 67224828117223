import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs"; 
import "./index.css";
import "./App.scss";
import Package from "./Pages/Package";
import PackageInner from "./Pages/PackageInner";



function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} /> 
        <Route path="/package" element={<Package />} /> 
        <Route path="/package/inner" element={<PackageInner />} /> 
      </Routes>
    </Router>
   <div className="side-social-bx">
    <a href="https://www.instagram.com" target="_blank" class="insta-button s-btn">
        <button>Instagram</button>
    </a>
   <a href="https://www.facebook.com" target="_blank" class="facebook-button s-btn">
        <button>Facebook</button>
    </a>
   </div>
    </>
  );
}

export default App;

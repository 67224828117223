import React, { useState, useEffect, useRef } from 'react';
import './pkgRoundSliderComponent.scss';

const PkgRoundSliderComponent = () => {
  const [position, setPosition] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const cardWidth = 260; 
  const visibleCards = 5; 
  const totalCards = 10; 
  const cards = Array.from({ length: totalCards }, (_, idx) => ({
    id: idx,
    image: 'https://etimg.etb2bimg.com/photo/94676301.cms',
    title: 'Udaipur',
  }));

  const sliderRef = useRef(null);

  const slideRight = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setPosition((prevPosition) => prevPosition - cardWidth);
    }
  };

  const slideLeft = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setPosition((prevPosition) => prevPosition + cardWidth);
    }
  };

  
  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (Math.abs(position) >= cardWidth * totalCards) {
        setPosition(0); 
      }
      if (position > 0) {
        setPosition(-cardWidth * (totalCards - visibleCards)); 
      }
    };

    const sliderElement = sliderRef.current;
    sliderElement.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      sliderElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [position, totalCards, visibleCards]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      slideRight();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='round-slider-container'>
      <div className='circle-slider'>
        <div
          ref={sliderRef}
          className='round-slider'
          style={{
            transform: `translateX(${position}px)`,
            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
          }}
        >
          {cards.slice(-visibleCards).map((card) => (
            <div className='round-slider-card' key={`clone-start-${card.id}`}>
              <img src={card.image} alt='img' />
              <p>{card.title}</p>
            </div>
          ))}
          {cards.map((card) => (
            <div className='round-slider-card' key={card.id}>
              <img src={card.image} alt='img' />
              <p>{card.title}</p>
            </div>
          ))}
          {cards.slice(0, visibleCards).map((card) => (
            <div className='round-slider-card' key={`clone-end-${card.id}`}>
              <img src={card.image} alt='img' />
              <p>{card.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className='slider-btn'>
        <svg
          id='swap-left'
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          onClick={slideLeft}
        >
          <path d='M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z' />
        </svg>

        <svg
          id='swap-right'
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          onClick={slideRight}
        >
          <path d='M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z' />
        </svg>
      </div>
    </div>
  );
};

export default PkgRoundSliderComponent;

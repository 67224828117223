import React from "react";
import "./cardComponent.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import CustomLeftArrow from "../Arrow/CustomLeftArrow";
// import CustomRightArrow from "../Arrow/CustomRightArrow";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 8 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 7 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

const CardComponent = (props) => {
  return (
    <div className="card-container">
      <Carousel
        swipeable={true}
        autoPlay={true}
        infinite={true}
        arrows={true}
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // customLeftArrow={<CustomLeftArrow />}
        // customRightArrow={<CustomRightArrow />}
      >
        {props.allStates.map((item, index) => (
          <div
            className="img-parent"
            key={index}
            style={{ height: props.isExploreUnexpected ? "21rem" : "19rem" }}
          >
            {!props.isExploreUnexpected ? (
              <div className="crd-txt">
                <div
                  className="text-cont"
                  style={{ top: props.isDescNeeded ? "11rem" : "14rem" }}
                >
                  <h3>{item.name}</h3>
                  {props.isDescNeeded ? <p>{item.desc}</p> : ""}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="card-img">
              <img src={item.img} alt={item.name} />
            </div>

            {props.isExploreUnexpected ? (
              <div className="crd-txt" style={{ top: "17.6rem" }}>
                <div className="text-explore-cont">
                  <h3>{item.name}</h3>
                  {props.isDescNeeded ? <p>{item.desc}</p> : ""}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardComponent;

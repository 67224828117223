import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import ExploreByStatesComponent from "../Components/ExploreByStatesComponent/ExploreByStatesComponent";
import SellingPackageComponent from "../Components/SellingPackageComponent/SellingPackageComponent";
import ExploreUnexpectedComponent from "../Components/ExploreUnexpectedComponent/ExploreUnexpectedComponent";
import RatingComponent from "../Components/RatingComponent/RatingComponent";
import ExploreHolidaysComponent from "../Components/ExploreHolidaysComponent/ExploreHolidaysComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";

const Homepage = () => {
  return (
    <div>
      <HeaderComponent />
      <BannerComponent />
      <div className="alignment">
        <ExploreByStatesComponent />
        <SellingPackageComponent />
        <ExploreHolidaysComponent />
        <ExploreUnexpectedComponent />
        <RatingComponent />
        <FooterComponent />
      </div>
    </div>
  );
};

export default Homepage;

import React from "react";
import { Link } from "react-router-dom";
import "./headerComponent.scss";
import Logo from "../../assets/img/Logo.png";

const HeaderComponent = () => {
  return (
    <div className="head">
      <div className="head-section">
        <div className="logo">
          <div>
            <img src={Logo} alt="img" />
          </div>
        </div>
        <div className="head-menu">
          <ul>
            <li>
              <Link to="/">
                <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_566_527)">
                    <path d="M11.7986 5.46263L9.97992 3.64397V1.54311C9.97992 1.16325 9.67209 0.855399 9.29167 0.855399C8.91212 0.855399 8.60429 1.16325 8.60429 1.54311V2.26837L7.25016 0.914188C6.58066 0.245054 5.41663 0.24624 4.74865 0.915399L0.201329 5.46263C-0.0671097 5.7316 -0.0671097 6.16681 0.201329 6.43537C0.469889 6.70427 0.90595 6.70427 1.17441 6.43537L5.7213 1.88802C5.86943 1.74068 6.1305 1.74068 6.27785 1.88759L10.8255 6.43537C10.8894 6.49929 10.9652 6.54998 11.0487 6.58453C11.1321 6.61908 11.2216 6.63682 11.3119 6.63671C11.4881 6.63671 11.6642 6.56976 11.7986 6.43537C12.0671 6.16684 12.0671 5.73162 11.7986 5.46263Z" fill="#ABFF23" />
                    <path d="M6.23889 3.19348C6.10678 3.06144 5.89287 3.06144 5.76114 3.19348L1.7613 7.19213C1.69793 7.25562 1.66234 7.34167 1.66235 7.43138V10.3479C1.66235 11.0322 2.21725 11.5871 2.9016 11.5871H4.88192V8.52023H7.11768V11.5871H9.09802C9.78235 11.5871 10.3372 11.0322 10.3372 10.3479V7.43138C10.3372 7.34144 10.3018 7.25526 10.2383 7.19213L6.23889 3.19348Z" fill="#ABFF23" />
                  </g>
                </svg>
                Home
              </Link>
              <span className="memu-line"></span>
            </li>
            <li>
              <Link to="/about-us">
                <svg width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_566_533)">
                    <path d="M9.38945 1.61085C7.24154 -0.53683 3.759 -0.537069 1.61085 1.61085C-0.537069 3.75876 -0.53683 7.2413 1.61085 9.38945C3.75876 11.5369 7.2413 11.5371 9.38945 9.38945C11.5371 7.2413 11.5369 3.759 9.38945 1.61085ZM6.21732 7.89151C6.21732 8.08177 6.14173 8.26425 6.00719 8.39879C5.87265 8.53333 5.69018 8.60891 5.49991 8.60891C5.30964 8.60891 5.12717 8.53333 4.99263 8.39879C4.85809 8.26425 4.7825 8.08177 4.7825 7.89151V5.02188C4.7825 4.83161 4.85809 4.64914 4.99263 4.5146C5.12717 4.38006 5.30964 4.30447 5.49991 4.30447C5.69018 4.30447 5.87265 4.38006 6.00719 4.5146C6.14173 4.64914 6.21732 4.83161 6.21732 5.02188V7.89151ZM5.48724 3.79559C5.07401 3.79559 4.79853 3.50289 4.80713 3.14155C4.79853 2.76276 5.07401 2.47891 5.49561 2.47891C5.91744 2.47891 6.18432 2.763 6.19316 3.14155C6.19293 3.50289 5.91768 3.79559 5.48724 3.79559Z" fill="#ABFF23" />
                  </g>
                </svg>
                About Us
              </Link>
              <span className="memu-line"></span>
            </li>
            <li>
              <Link to="/contact-us">
                <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_566_538)">
                    <path d="M11.5 1.39589C11.5 0.924638 11.1168 0.541138 10.646 0.541138H5.36121C4.89034 0.541138 4.50684 0.924638 4.50684 1.39589V4.71801C4.50684 5.18926 4.89034 5.57276 5.36121 5.57276H7.74871C7.81584 5.57276 7.88071 5.59789 7.92934 5.64351L9.68071 7.28426C9.68071 7.28426 9.71709 7.31826 9.74784 7.30726C9.78559 7.29364 9.76984 7.24589 9.76984 7.24589V5.83676C9.76984 5.69076 9.83934 5.55626 9.95121 5.44451C10.0629 5.33476 10.1368 5.226 10.1368 5.08276V2.29387C10.1368 2.15005 10.0629 2.03164 9.95121 1.92276C9.83934 1.81289 9.71709 1.73626 9.68071 1.73626L7.92934 1.39589C7.88071 1.35026 7.81584 1.32589 7.74871 1.32589H5.36121C4.89034 1.32589 4.50684 1.70939 4.50684 2.18064V2.66816C4.50684 2.69801 4.49794 2.73181 4.48509 2.76801L4.19619 3.30299C4.07854 3.47924 4.01634 3.69151 4.01634 3.90565V5.55751C4.01634 5.9358 4.21521 6.32527 4.51684 6.58387C4.86368 6.8616 5.22168 7.15901 5.5803 7.44864C5.85093 7.75551 6.14533 8.05801 6.46093 8.37811L6.991 8.75801L7.59321 9.42387C7.86073 9.75024 8.1468 10.0711 8.45232 10.3958C8.75784 10.7205 9.08243 11.0483 9.44134 11.3871C9.79934 11.7246 10.1906 12.0489 10.6112 12.0003C11.0057 11.9631 11.3683 11.6208 11.7212 11.2591C11.9804 10.9799 12.2718 10.7517 12.4645 10.4289C12.681 10.073 12.7569 9.68547 12.6423 9.34497C12.5404 9.03759 12.3154 8.78838 11.998 8.55764C11.9665 8.52828 11.9462 8.48543 11.9306 8.44776L11.5 7.99389V1.39589Z" fill="#ABFF23" />
                  </g>
                </svg>
                Contact Us
              </Link>
              <span className="memu-line"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;

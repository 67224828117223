import React from "react";
import "./pkgCardComponent.scss";
import { Link } from "react-router-dom";

const PkgCardComponent = () => {
  return (
    <div className="pkg-card-box">
      <div className="pkg-card-img">
        <img
          src={
            "https://www.travelturtle.world/wp-content/uploads/2024/03/6585d85934a171a9a052c170_traveling-based-on-fare-deals.jpeg"
          }
          alt="img"
        />
      </div>

      <div className="pkg-card-item">


        <div className="pkg-card-item-top">
          <h2>Kerala for Nature Lovers</h2>
          <div className="pkg-card-item-top-level">
            <ul>
              <li>2N Munnar</li>
              <li>2N Thekkady</li>
              <li>2N 1N Alleppey</li>
            </ul>
          </div>
        </div>

        <div className="pkg-card-item-contain">
          <div className="pkg-card-item-contain-list">
            <ul>
              <li>Round Trip Flights</li>
              <li>Airport Transfers</li>
              <li>Intercity Car Transfers</li>
              <li>8 Activities</li>
              <li>4 Star Hotels & Houseboat gyufyofk </li>
              <li>Selected Meals</li>
            </ul>
          </div>

          <div className="pkg-card-item-options">
            <p>
              <img
                src="https://cdn-icons-png.freepik.com/512/5290/5290058.png?uid=R163368387&ga=GA1.1.363645423.1726296904"
                alt="img"
              />
              Day Excursion to Vagamon
            </p>
            <p>
              <img
                src="https://cdn-icons-png.freepik.com/512/5290/5290058.png?uid=R163368387&ga=GA1.1.363645423.1726296904"
                alt="img"
              />
              Highlight IconTraditional Food
            </p>
            <p>
              <img
                src="https://cdn-icons-png.freepik.com/512/5290/5290058.png?uid=R163368387&ga=GA1.1.363645423.1726296904"
                alt="img"
              />
              Highlight IconSpice Plantation Tour
            </p>
          </div>



          <Link to="/package/inner" style={{ textDecoration: 'none' }}>
          <div className="pkg-card-item-green">
            <p>
              Includes extra <span>₹2,968</span>
              <br />
              discount for packages with flights
            </p>

            <p>
              <span>₹2,968</span>/Person
              <br />
              Total Price ₹76,491
            </p>
          </div>
          </Link>



          <div className="pkg-card-bottom">
            <button>
              <svg
                fill="none"
                height="20"
                viewBox="0 0 92 92"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  fill="#00d95f"
                  height="91.5618"
                  rx="45.7809"
                  width="91.5618"
                />
                <g fill="#fff">
                  <path d="m22.8906 66.9759 3.2847-12.2132c-2.649-4.7577-3.4906-10.3095-2.3704-15.6366 1.1202-5.3272 4.1271-10.0724 8.4689-13.3649 4.3418-3.2924 9.7273-4.9113 15.1683-4.5596 5.4409.3517 10.5723 2.6505 14.4525 6.4745 3.8803 3.824 6.2491 8.9167 6.6718 14.3436.4226 5.4269-1.1291 10.824-4.3706 15.2009-3.2415 4.3768-7.9553 7.4399-13.2763 8.6271-5.3211 1.1872-10.8925.4189-15.6919-2.1639zm12.9318-7.8624.763.4518c3.4763 2.0571 7.5366 2.9084 11.5482 2.4212 4.0115-.4873 7.7489-2.2856 10.6298-5.1148 2.8809-2.8293 4.7434-6.5304 5.2973-10.5267.5539-3.9964-.2319-8.0634-2.235-11.5673s-5.1109-6.2479-8.8392-7.8044c-3.7283-1.5564-7.8675-1.8379-11.7728-.8005-3.9052 1.0373-7.357 3.3353-9.8175 6.5357s-3.7915 7.1234-3.7855 11.1578c-.0032 3.3452.9234 6.6257 2.6769 9.4762l.4785.7876-1.8363 6.8167z" />
                  <path
                    clip-rule="evenodd"
                    d="m54.3404 47.0031c-.4473-.3602-.971-.6137-1.5313-.7412-.5602-.1275-1.1422-.1257-1.7017.0053-.8405.3486-1.3837 1.6654-1.9268 2.3239-.1145.1578-.2828.2685-.4733.3113s-.39.0147-.5612-.079c-3.0774-1.2032-5.6568-3.4099-7.3194-6.2615-.1419-.1779-.209-.4039-.1873-.6302.0218-.2264.1306-.4355.3036-.5834.6058-.5987 1.0506-1.3402 1.2932-2.156.0539-.8998-.1526-1.7961-.5948-2.5821-.3419-1.1018-.9926-2.0829-1.8751-2.8273-.4552-.2044-.96-.2729-1.4533-.1973s-.9542.292-1.3271.6233c-.6473.5575-1.161 1.2529-1.5034 2.0349-.3425.7821-.5048 1.6308-.4751 2.4837.0019.479.0628.956.181 1.4202.3003 1.1153.7621 2.1809 1.3708 3.163.4391.7523.9183 1.4807 1.4354 2.1819 1.6805 2.3032 3.793 4.2587 6.2202 5.758 1.218.7619 2.5197 1.3814 3.8795 1.8462 1.4126.6392 2.9722.8846 4.5132.71.878-.1326 1.7099-.4787 2.4225-1.0078.7126-.529 1.284-1.2248 1.664-2.0261.2232-.484.291-1.0252.1939-1.5492-.2327-1.0716-1.6682-1.7042-2.5475-2.2206z"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              Share
            </button>
            <p>
              <svg
                fill="#000000"
                width="20px"
                height="20px"
                viewBox="-1.7 0 20.4 20.4"
                xmlns="http://www.w3.org/2000/svg"
                class="cf-icon-svg"
              >
                <path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zM5.909 9.536a1.812 1.812 0 1 0-1.812-1.813 1.814 1.814 0 0 0 1.812 1.813zm0-1.109a.704.704 0 1 1 .704-.704.705.705 0 0 1-.704.704zm5.72-1.402a.792.792 0 1 0-1.316-.88l-4.924 7.372a.792.792 0 0 0 1.317.88zm1.286 5.837a1.812 1.812 0 1 0-1.812 1.812 1.814 1.814 0 0 0 1.812-1.812zm-1.109 0a.704.704 0 1 1-.703-.704.705.705 0 0 1 .703.704z" />
              </svg>
              Promo Icon Extra <span>₹4,892</span>&nbsp;off.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PkgCardComponent;

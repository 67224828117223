import "./communicationComponent.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';



const CommunicationComponent = () => {
  return (
    <>



<div className="contact-card">
  <div className="section">
    <div className="label">Phone</div>
    <div className="contact-info">
      <div className="contact-item">
        <i className="fas fa-phone"></i>
        <span>+91 89108 45933</span>
      </div>
      <div className="contact-item">
        <i className="fas fa-phone"></i>
        <span>+91 98361 55825</span>
      </div>
    </div>
  </div>

  <div className="section">
    <div className="label">Email</div>
    <div className="contact-info">
      <div className="contact-item">
        <i className="fas fa-envelope"></i>
        <span>explore@ecstaticexplorers.com</span>
      </div>
    </div>
  </div>

  <div className="section">
    <div className="label">Location</div>
    <div className="contact-info">
      <div className="contact-item">
        <i className="fas fa-map-marker-alt"></i>
        <span>
          Premises no. D-206, Mondal Ganthi, Arjunpur, Kaikhali, North Dumdum,
          West Bengal, India. Kolkata -700052
        </span>
      </div>
    </div>
  </div>

  <div className="message-section">
    <h4>We'd love to hear from you!</h4>
    <p>
      Have a question about our services? Want to see if your particular
      vacation is one we can help with? We'd love to hear from you!
    </p>
    <div className="whatsapp">
      <i className="fab fa-whatsapp"></i>
      <span>Message us on WhatsApp</span>
    </div>
  </div>
</div>




    </>
  );
};

export default CommunicationComponent;

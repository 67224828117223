import React, { useState } from "react";
import "./pkgBannerComponent.scss";

const PkgBannerComponent = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const popupImages = [
    "https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg",
    "https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg/ff0000",
    "https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg/00ff00",
    "https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg/0000ff",
  ];

  const swapImage = (direction) => {
    if (direction === "left") {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : popupImages.length - 1
      );
    } else {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < popupImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  return (
    <div className="banner-pkg-container">
      <div className="pkg-gride-box">
        <div className="pkg-one">
          <div className="pkg-image-box">
            <img src="https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg" alt="Image 1" />
          </div>
        </div>

        <div className="pkg-two">
          <div id="pkg-container-box" className="pkg-container">
            <div className="pkg-image-box">
              <img src="https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg" alt="Image 1" />
            </div>
            <div className="pkg-image-box">
              <img src="https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg" alt="Image 2" />
            </div>
            <div className="pkg-image-box">
              <img src="https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg" alt="Image 3" />
            </div>
            <div className="pkg-image-box" onClick={() => setIsPopupOpen(true)}>
              <img src="https://www.pixelstalk.net/wp-content/uploads/images6/4K-Travel-Wallpaper-HD-Free-download.jpg" alt="View More" />
              <div className="pkg-view-more">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_459_948)">
                    <path d="M36.3604 50C36.0104 50 35.6521 49.9562 35.2979 49.8625L3.0833 41.2354C2.02119 40.9406 1.11733 40.2405 0.566353 39.2859C0.0153755 38.3312 -0.138642 37.1983 0.137466 36.1312L4.20205 20.9812C4.27697 20.7185 4.45213 20.4956 4.6898 20.3608C4.92747 20.226 5.20859 20.1899 5.47259 20.2604C5.73658 20.3309 5.9623 20.5024 6.10109 20.7377C6.23988 20.9731 6.28063 21.2536 6.21455 21.5187L2.15205 36.6646C1.86247 37.7687 2.52497 38.9187 3.63122 39.225L35.8333 47.8479C36.364 47.9891 36.929 47.914 37.4044 47.639C37.8798 47.3641 38.2266 46.9117 38.3687 46.3812L39.9958 40.3521C40.0314 40.2199 40.0926 40.0961 40.176 39.9876C40.2595 39.8791 40.3634 39.7882 40.482 39.7199C40.6006 39.6516 40.7315 39.6073 40.8672 39.5896C41.0029 39.5719 41.1407 39.5811 41.2729 39.6167C41.405 39.6522 41.5289 39.7135 41.6373 39.7969C41.7458 39.8803 41.8368 39.9843 41.9051 40.1029C41.9734 40.2215 42.0176 40.3524 42.0354 40.488C42.0531 40.6237 42.0439 40.7616 42.0083 40.8937L40.3833 46.9146C40.1464 47.8003 39.6236 48.5829 38.8961 49.1408C38.1686 49.6988 37.2772 50.0008 36.3604 50Z" fill="#9EF01A" />
                    <path d="M45.8335 37.5H12.5002C10.2022 37.5 8.3335 35.6312 8.3335 33.3333V8.33332C8.3335 6.03541 10.2022 4.16666 12.5002 4.16666H45.8335C48.1314 4.16666 50.0002 6.03541 50.0002 8.33332V33.3333C50.0002 35.6312 48.1314 37.5 45.8335 37.5ZM12.5002 6.24999C11.3522 6.24999 10.4168 7.18541 10.4168 8.33332V33.3333C10.4168 34.4812 11.3522 35.4167 12.5002 35.4167H45.8335C46.9814 35.4167 47.9168 34.4812 47.9168 33.3333V8.33332C47.9168 7.18541 46.9814 6.24999 45.8335 6.24999H12.5002Z" fill="#9EF01A" />
                    <path d="M18.7501 18.75C16.4522 18.75 14.5834 16.8812 14.5834 14.5833C14.5834 12.2854 16.4522 10.4167 18.7501 10.4167C21.048 10.4167 22.9168 12.2854 22.9168 14.5833C22.9168 16.8812 21.048 18.75 18.7501 18.75ZM18.7501 12.5C17.6022 12.5 16.6668 13.4354 16.6668 14.5833C16.6668 15.7312 17.6022 16.6667 18.7501 16.6667C19.898 16.6667 20.8334 15.7312 20.8334 14.5833C20.8334 13.4354 19.898 12.5 18.7501 12.5ZM9.52094 35.2708C9.31507 35.2708 9.11384 35.2097 8.94264 35.0954C8.77145 34.9811 8.63796 34.8186 8.55905 34.6284C8.48014 34.4383 8.45934 34.229 8.49928 34.0271C8.53922 33.8251 8.6381 33.6395 8.78344 33.4937L18.623 23.6542C19.8022 22.475 21.8626 22.475 23.0418 23.6542L25.9709 26.5833L34.0793 16.8542C34.3703 16.5051 34.734 16.2237 35.1448 16.0294C35.5557 15.8351 36.004 15.7326 36.4584 15.7292H36.4814C36.9321 15.7289 37.3775 15.8262 37.7871 16.0142C38.1967 16.2023 38.5607 16.4768 38.8543 16.8187L49.7501 29.5312C49.8409 29.6348 49.9103 29.7554 49.9542 29.8859C49.9981 30.0165 50.0157 30.1545 50.0059 30.2919C49.9961 30.4293 49.9592 30.5634 49.8972 30.6864C49.8353 30.8094 49.7496 30.9189 49.645 31.0086C49.5405 31.0983 49.4192 31.1664 49.2881 31.2088C49.1571 31.2513 49.0189 31.2674 48.8817 31.2561C48.7444 31.2449 48.6107 31.2065 48.4883 31.1432C48.366 31.0799 48.2574 30.993 48.1689 30.8875L37.273 18.175C37.1755 18.0605 37.0541 17.9687 36.9173 17.9061C36.7806 17.8435 36.6318 17.8115 36.4814 17.8125C36.3294 17.8166 36.1799 17.852 36.0422 17.9165C35.9046 17.981 35.7817 18.0733 35.6814 18.1875L26.8418 28.7937C26.7491 28.9051 26.6341 28.9958 26.5042 29.0601C26.3743 29.1243 26.2324 29.1606 26.0876 29.1667C25.9428 29.1757 25.7978 29.1532 25.6625 29.1006C25.5273 29.0481 25.405 28.9669 25.3043 28.8625L21.5689 25.1271C21.37 24.9386 21.1064 24.8336 20.8324 24.8336C20.5584 24.8336 20.2948 24.9386 20.0959 25.1271L10.2564 34.9667C10.1598 35.0633 10.0452 35.1399 9.91897 35.1921C9.79277 35.2443 9.65751 35.271 9.52094 35.2708Z" fill="#9EF01A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_459_948">
                      <rect width="50" height="50" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p >View more images</p>

              </div>
            </div>
          </div>
        </div>
      </div>

     {isPopupOpen && (
        <div className="pkg-popup">
          <div className="close-btn" onClick={() => setIsPopupOpen(false)}>
            X
          </div>
          <button className="swap-btn left" onClick={() => swapImage("left")}>
            ←
          </button>
          <img
            src={popupImages[currentImageIndex]}
            alt="Popup"
            className="popup-image"
          />
          <button className="swap-btn right" onClick={() => swapImage("right")}>
            →
          </button>
        </div>
      )}

    </div>
  );
};

export default PkgBannerComponent;

import React from "react";
import "./ratingComponent.scss";
import { FaStar } from "react-icons/fa";
import GoogleRatingComponent from "../GoogleRatingComponent/GoogleRatingComponent";






const RatingComponent = () => {
  return (
    <div className="rating-con">
      <h1>Rating & Reviews</h1>
      <div className="container">
        <div className="rating-img-box">
          <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
        </div>
        <div >
        <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
          <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
        </div>
        <div className="rating-root">
          <GoogleRatingComponent />
        </div>
        <div >
        <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div> <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
        </div>
        <div >
        <div className="rating-box">
            <div className="rating-img">
              <img src="https://t4.ftcdn.net/jpg/01/98/68/53/360_F_198685380_UiiR2lCHgg7raR054Dv9v5cuOYdLCEdX.jpg" alt="#" />
            </div>
            <div className="rating-info">
              <h6>Gopal Mondal</h6>
              <p> 3 days ago</p>
              <span>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
              </span>
            </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting Industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s,</p>
          </div>
        </div>
      </div>
      <div className="view-more">
        <button>View More</button>
      </div>
    </div>
  );
};

export default RatingComponent;

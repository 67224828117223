import React from "react";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import ReachUsComponent from "../Components/ReachUsComponent/ReachUsComponent";
import CommunicationComponent from "../Components/CommunicationComponent/CommunicationComponent";
import MapComponent from "../Components/MapComponent/MapComponent";


const ContactUs = () => {
  return (
    <div>
      <HeaderComponent />
      <BannerComponent />
      <div className="alignment">

        <section className="contact-us-section">


          <div className="flex items-center space-x-2 breadcrumb-container">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="home-icon">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9.75L12 3l9 6.75v8.25A2.25 2.25 0 0118.75 20H5.25A2.25 2.25 0 013 18.25V9.75z" />
            </svg>

            <svg className="breadcrumb-separator" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
              <path d="M9 5l7 7-7 7" />
            </svg>
            <span className="breadcrumb-text">Inner</span>
            <svg className="breadcrumb-separator" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
              <path d="M9 5l7 7-7 7" />
            </svg>
            <span className="breadcrumb-highlight">Inner</span>
          </div>



          <div className="contact-us">
            <div className="contact-box">
              <CommunicationComponent />
            </div>
            <div className="inq-box">
              <ReachUsComponent />
            </div>
            <div className="map-box">
              <MapComponent />
            </div>
          </div>




        </section>





        <FooterComponent />




      </div>
    </div>
  );
};

export default ContactUs;
import React, { useEffect, useRef } from 'react';

const MapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapScript = () => {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_REAL_API_KEY`;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
        if (window.google) {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 40.712776, lng: -74.005974 },
            zoom: 8,
          });
        }
      });
    };

    if (!window.google) {
      loadGoogleMapScript();
    } else {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 40.712776, lng: -74.005974 },
        zoom: 8,
      });
    }
  }, []);

  return (
    <div>
      <div ref={mapRef} style={{ minHeight: '400px', width: '100%' }}></div>
    </div>
  );
};

export default MapComponent;

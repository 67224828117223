import React, { useRef, useState } from "react";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import HeaderComponent from "../Components/HeaderComponent/HeaderComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import PkgRoundSliderComponent from "../Components/PkgRoundSliderComponent/PkgRoundSliderComponent";
import PkgFilterComponent from "../Components/PkgFilterComponent/PkgFilterComponent";
import PkgCardComponent from "../Components/PkgCardComponent/PkgCardComponent";
import PkgFilterSearchComponent from "../Components/PkgFilterSearchComponent/PkgFilterSearchComponent";

const Package = () => {
  const pkgItemsRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [visibleCards, setVisibleCards] = useState(4); 

  const handleSlide = (direction) => {
    const container = pkgItemsRef.current;
    const containerWidth = container.offsetWidth;
    const itemsWidth = container.scrollWidth;

    const maxTranslateX = -(itemsWidth - containerWidth);

    const moveDistance = containerWidth / 3;

    if (direction === "right" && translateX > maxTranslateX) {
      setTranslateX((prev) => Math.max(prev - moveDistance, maxTranslateX));
    } else if (direction === "left" && translateX < 0) {
      setTranslateX((prev) => Math.min(prev + moveDistance, 0));
    }
  };

  const packageCategories = [
    { name: "All Packages", count: 243 },
    { name: "Pilgrimage", count: 18 },
    { name: "Long Weekend", count: 17 },
    { name: "Wildlife", count: 24 },
    { name: "Luxury", count: 39 },
    { name: "Adventure", count: 25 },
    { name: "Beach", count: 20 },
    { name: "Trekking", count: 15 },
    { name: "Budget", count: 30 },
    { name: "Honeymoon", count: 22 },
  ];

  const dropdownOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen); 
  };

  const handleViewMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 4);
  };

  return (
    <>
      <HeaderComponent />
      <BannerComponent />
      <div className="flex items-center space-x-2 breadcrumb-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="home-icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 9.75L12 3l9 6.75v8.25A2.25 2.25 0 0118.75 20H5.25A2.25 2.25 0 013 18.25V9.75z"
          />
        </svg>

        <svg
          className="breadcrumb-separator"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M9 5l7 7-7 7" />
        </svg>
        <span className="breadcrumb-text">Inner</span>
        <svg
          className="breadcrumb-separator"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M9 5l7 7-7 7" />
        </svg>
        <span className="breadcrumb-highlight">Inner</span>
      </div>
      <div className="alignment">
        <PkgRoundSliderComponent />

        <div className="pkg-Container">
          <div className="pkg-filter">
            <div className="pkg-filter-header">
              <h4>Filters</h4>
            </div>
            <div className="filter-item">
              <PkgFilterComponent />
              <PkgFilterComponent />
              <PkgFilterSearchComponent />
              <PkgFilterSearchComponent />
              <PkgFilterSearchComponent />
            </div>
          </div>

          <div className="pkg-contain">
            <div className="pkg-contain-header">
              <div className="pkgs-item">
                <div
                  className="pkg-btn-items"
                  ref={pkgItemsRef}
                  style={{ transform: `translateX(${translateX}px)` }}
                >
                  {packageCategories.map((pkg, index) => (
                    <button className="pkg-item-btn-active" key={index}>
                      {pkg.name} <span>({pkg.count})</span>
                    </button>
                  ))}
                </div>
              </div>

              <div className="navigation">
                <svg
                  id="sp-left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  onClick={() => handleSlide("left")}
                >
                  <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
                </svg>

                <svg
                  id="sp-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  onClick={() => handleSlide("right")}
                >
                  <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                </svg>
              </div>
            </div>

            <div className="short-drop">
              <p>
                Sort By:
                <button id="short" onClick={toggleDropdown}>
                  Popular
                  <svg
                    className={isDropdownOpen ? "active" : ""}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path d="M12 17.414L3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                  </svg>
                </button>
              </p>

              {isDropdownOpen && (
                <div className="dropdown-content">
                  <ul>
                    {dropdownOptions.map((option, index) => (
                      <li key={index}>{option.label}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="pkg-card-container">
              {Array.from({ length: visibleCards }).map((_, index) => (
                <PkgCardComponent key={index} />
              ))}

              {visibleCards < 20 && ( 
                <button id="viewmore" onClick={handleViewMore}>
                  View More
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <FooterComponent /> */}
    </>
  );
};

export default Package;

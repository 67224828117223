import React, { useState } from "react";
import './pkgFilterComponent.scss';

const PkgFilterComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [minBudget, setMinBudget] = useState(5000);  // Minimum value
  const [maxBudget, setMaxBudget] = useState(20000); // Maximum value
  const [selectedRange, setSelectedRange] = useState({
    below25k: false,
    between25k35k: false,
    between35k40k: false,
    above40k: false,
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMinSliderChange = (e) => {
    setMinBudget(parseInt(e.target.value));
    clearCheckboxSelection();
  };

  const handleMaxSliderChange = (e) => {
    setMaxBudget(parseInt(e.target.value));
    clearCheckboxSelection();
  };

  const clearCheckboxSelection = () => {
    setSelectedRange({
      below25k: false,
      between25k35k: false,
      between35k40k: false,
      above40k: false,
    });
  };

  const handleCheckboxChange = (e, min, max, range) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setMinBudget(min);
      setMaxBudget(max);
      setSelectedRange({
        ...selectedRange,
        [range]: true,
      });
    } else {
      setSelectedRange({
        ...selectedRange,
        [range]: false,
      });
    }
  };

  return (
    <div className="budget-filter">
      <div className="budget-heading" onClick={toggleDropdown}>
        <h2>Budget (Per Person)</h2>
        <span>{isOpen ? "▲" : "▼"}</span>
      </div>

      {isOpen && (
        <div className="budget-dropdown">
          <div className="slider-container">
            
            <div className="range-slider">
              <input
                type="range"
                min="5000"
                max="40000"
                value={minBudget}
                onChange={handleMinSliderChange}
                className="slider"
              />
              <input
                type="range"
                min="5000"
                max="40000"
                value={maxBudget}
                onChange={handleMaxSliderChange}
                className="slider"
              />
            </div>
            <div className="min-max-values">
              <span>₹{minBudget.toLocaleString()}</span>
              <span className="max-amount">₹{maxBudget.toLocaleString()}</span>
            </div>
          </div>

          <div className="budget-options">
            <label>
              <input
                type="checkbox"
                checked={selectedRange.below25k}
                onChange={(e) => handleCheckboxChange(e, 5000, 25000, 'below25k')}
              />
              <span className="amount">
              <span> &lt; ₹25,000</span>
              <span className="count">(92)</span>
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.between25k35k}
                onChange={(e) => handleCheckboxChange(e, 25000, 35000, 'between25k35k')}
              />
              <span className="amount">
              <span>₹25,000 - ₹35,000</span>
              <span className="count">(139)</span>

              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.between35k40k}
                onChange={(e) => handleCheckboxChange(e, 35000, 40000, 'between35k40k')}
              />
              <span className="amount">
              <span>₹35,000 - ₹40,000</span>
              <span className="count">(68)</span>
              </span>
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedRange.above40k}
                onChange={(e) => handleCheckboxChange(e, 40000, 40000, 'above40k')}
              />
              <span className="amount">
              <span>&gt; ₹40,000</span>
              <span className="count">(71)</span>
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default PkgFilterComponent;
